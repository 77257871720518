import { FaDiscord, FaTwitter } from 'react-icons/fa'
import { HiLink } from 'react-icons/hi'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

function Header() {
  return(
    <div className="relative w-full flex justify-start items-center md:py-2 z-50">
      <div className="flex justify-start items-base gap-2 opacity-90">
        <div className="flex justify-start items-baseline gap-2">
          <div className="text-[#ddd6f7] text-xl font-semibold title">☕</div>
          <h1 className="text-[#ddd6f7] text-xl font-semibold title">
            coffeeweed
          </h1>
          <h2 className="text-[#ddd6f7]/70 text-sm font-semibold title">
            App Directory
          </h2>
        </div>
      </div>
    </div>
  )
}

function Banner() {
  return(
    <div className="h-3/6 max-h-[15rem] md:max-h-[28.5rem] w-full rounded-lg overflow-hidden flex justify-start items-start">
      <img src={'./assets/banner.png'} alt="banner" className="object-cover h-full w-full rounded-lg" />
    </div>
  )
}

function Bottom() {
  return(
    <div className="w-full flex flex-col md:flex-row justify-start items-start text-[#ddd6f7]/80 h-full -translate-y-[2.5rem]">
      <LinksSection />
      <OverviewSection />
    </div>
  )
}

function LinksSection() {
  return(
    <div className="flex flex-col justify-start items-center w-full md:w-2/6">
      <div className="h-full w-full p-2 text-sm font-semibold flex flex-col gap-2 justify-start items-start">
        <a className="font-extrabold title uppercase">Links</a>
        <div className="w-full grid grid-cols-2 justify-evenly md:flex md:flex-col md:justify-start md:items-start gap-2 text-xs md:text-sm text-nowrap">
          <a className="mx-auto md:mx-0 w-fit hover:text-[#4d5feb]/60 transition-all transform-gpu ease-out duration-100 flex items-center gap-2 cursor-pointer" onClick={() => window.open('https://discord.gg/uFDHWTvm', '_blank')}>
            <FaDiscord /> Support Server
          </a>
          <a className="mx-auto md:mx-0 w-fit hover:text-[#4d5feb]/60 transition-all transform-gpu ease-out duration-100 flex items-center gap-2 cursor-pointer" onClick={() => window.open('https://x.com/coffeeweed_eth', '_blank')}>
            <FaTwitter /> Dev's Twitter
          </a>
          <a className="mx-auto md:mx-0 w-fit hover:text-[#4d5feb]/60 transition-all transform-gpu ease-out duration-100 flex items-center gap-2 cursor-pointer" onClick={() => window.open('https://gist.githubusercontent.com/erc1337-Coffee/52cdeb21aefbae7aadefbd88dcab4611/raw/887b320a0240f29c03cb7fa83ccfc1ad04362f05/gistfile1.txt', '_blank')}>
            <HiLink /> Terms of Service
          </a>
          <a className="mx-auto md:mx-0 w-fit hover:text-[#4d5feb]/60 transition-all transform-gpu ease-out duration-100 flex items-center gap-2 cursor-pointer" onClick={() => window.open('https://gist.githubusercontent.com/erc1337-Coffee/3a1ff9c40f0180e00900e7be43683342/raw/c2509f7bbf5a00e51598870043cd1935594e73ab/gistfile1.txt', '_blank')}>
            <HiLink /> Privacy Policy
          </a>
        </div>
      </div>
    </div>
  )
}

function OverviewSection() {
  return(
    <div className="flex flex-col justify-start items-start gap-2 w-full max-w-[45rem] p-4 h-full">
      <a className="font-extrabold title uppercase">Overview</a>
      <Tabs className="w-full overflow-y-hidden">
        <TabList className="flex gap-2 mb-4 border-b border-white/10">
          <Tab 
            className="px-4 py-2 text-sm font-semibold cursor-pointer outline-none text-[#ddd6f7]/60"
            selectedClassName="text-[#ddd6f7] border-b-2 border-[#4d5feb]"
          >
            Description
          </Tab>
          <Tab 
            className="px-4 py-2 text-sm font-semibold cursor-pointer outline-none text-[#ddd6f7]/60"
            selectedClassName="text-[#ddd6f7] border-b-2 border-[#4d5feb]"
          >
            Commands
          </Tab>
          <Tab 
            className="px-4 py-2 text-sm font-semibold cursor-pointer outline-none text-[#ddd6f7]/60"
            selectedClassName="text-[#ddd6f7] border-b-2 border-[#4d5feb]"
          >
            Updates
          </Tab>
        </TabList>

        <TabPanel>
          <div className="gap-4 flex flex-col select-text rounded-lg bg-white/5 border border-[#ffffff0a] p-4 shadow-inner text-sm animate-fadeIn max-h-44 md:max-h-64 overflow-y-auto">
            <p className="text-[#ddd6f7]/80 leading-relaxed">
              RunesRick is your ultimate Discord companion for navigating the world of Bitcoin Ordinals and Runes.
              <br/>
              With real-time market data, instant verification features, and comprehensive tracking capabilities, 
              it streamlines your crypto experience directly within Discord.
            </p>
            <p className="text-[#ddd6f7]/80 leading-relaxed">
              Report bugs and ask for new features in the support server: <a className="text-[#4d5feb] hover:text-[#3d4cb8] transition-all transform-gpu ease-out duration-100" onClick={() => window.open('https://discord.gg/uFDHWTvm', '_blank')}>https://discord.gg/uFDHWTvm</a>
              <br/>
              <b>Thanks a lot for using RunesRick 💖✨</b>
            </p>
          </div>
        </TabPanel>

        <TabPanel>
          <div className="select-text rounded-lg bg-white/5 border border-[#ffffff0a] p-4 shadow-inner animate-fadeIn max-h-44 md:max-h-64 overflow-y-auto">
            <div className="space-y-3">
              <div className="text-sm">
                <p className="text-[#4d5feb] font-semibold">/help</p>
                <p className="text-[#ddd6f7]/60">Display all available commands</p>
              </div>
              <div className="text-sm">
                <p className="text-[#4d5feb] font-semibold">/rune [TICKER]</p>
                <p className="text-[#ddd6f7]/60">Show market data about a ticker</p>
              </div>
              <div className="text-sm">
                <p className="text-[#4d5feb] font-semibold">/ord [SLUG]</p>
                <p className="text-[#ddd6f7]/60">Show market data about an ordinals collection</p>
              </div>
              <div className="text-sm">
                <p className="text-[#ddd6f7]/60 bg-black/20 p-2 rounded-lg w-full">
                  <span className="font-semibold">Wildcard authorized, will use the 1st result with the best volume</span>
                  <div className="">
                    /rune SAIKO === /rune SAIKO•HAMSTER
                    <br />
                    /ord angels === /ord based angels
                  </div>
              </p>
              </div>
            </div>
          </div>
        </TabPanel>

        <TabPanel>
          <div className="select-text rounded-lg bg-white/5 border border-[#ffffff0a] p-4 shadow-inner animate-fadeIn max-h-44 md:max-h-64 overflow-y-auto">
            <div className="space-y-3">
              <div className="text-sm">
                <p className="text-[#ddd6f7] font-semibold">v2.0.0 - Latest</p>
                <p className="text-[#ddd6f7]/60">• New art style - thanks @F___T___W ❤️</p>
                <p className="text-[#ddd6f7]/60">• Enhanced runes pings</p>
                <p className="text-[#ddd6f7]/60">• Added ordinals support</p>
                <p className="text-[#ddd6f7]/60">• Switched from passive listening to slash commands to comply with discord's rules</p>
              </div>
              <div className="text-sm">
                <p className="text-[#ddd6f7] font-semibold">v1.1.0</p>
                <p className="text-[#ddd6f7]/60">• New UI improvements</p>
                <p className="text-[#ddd6f7]/60">• Improved error handling</p>
              </div>
              <div className="text-sm">
                <p className="text-[#ddd6f7] font-semibold">v1.1.0</p>
                <p className="text-[#ddd6f7]/60">• Added runes support</p>
              </div>
            </div>
          </div>
        </TabPanel>
      </Tabs>
    </div>
  )
}

function Middle() {
  return(
    <div className="m-auto h-1/6 w-full flex justify-between items-center gap-4 md:pl-6 p-2 -translate-y-1/2">
      <div className="h-full aspect-square rounded-full overflow-hidden flex justify-center items-center">
        <img src={'./assets/pfp.png'} alt="pfp" className="object-cover aspect-square w-full rounded-full md:border-8 border-4 border-[#261e26]" />
      </div>
      <div className="h-full flex md:flex-row flex-col justify-center md:justify-evenly items-center md:gap-4 select-none">
        <div className="flex justify-start items-center md:flex-row bg-[#261e26] px-4 py-2 rounded-lg flex-col gap-2">
          <h1 className="text-center md:text-left text-[#ddd6f7] md:text-3xl text-lg font-black title">
            RunesRick
          </h1>
          <h1 className="text-center md:text-left text-[#ddd6f7]/60 font-semibold md:text-sm text-xs title">
            Discord assistant for ordinals and runes
          </h1>
        </div>
      </div>
      <div className="flex md:flex-row flex-col gap-4 justify-center md:justify-evenly md:items-center md:gap-4 select-none h-fit my-auto">
        <div className="flex justify-center items-center max-w-24 text-nowrap py-2 px-5 rounded-md bg-[#45474d] hover:bg-[#62646c] active:bg-[#62646f] cursor-pointer transition-all transform-gpu ease-out duration-100" onClick={(e) => {
          navigator.clipboard.writeText('https://discord.gg/uFDHWTvm');
          const button = e.currentTarget.querySelector('p');
          if(button.textContent === 'Copied ✨') {
            return;
          }
          const originalText = button.textContent;
          button.textContent = 'Copied ✨';
          setTimeout(() => {
            button.textContent = originalText;
          }, 1000);
        }}>
          <p className="text-[#ddd6f7] text-sm text-center font-semibold title">Copy link</p>
        </div>
        <div className="flex justify-center items-center max-w-24 text-nowrap py-2 px-5 rounded-md bg-[#4d5feb] hover:bg-[#3d4cb8] active:bg-[#3d4cbf] cursor-pointer transition-all transform-gpu ease-out duration-100" onClick={() => { window.open('https://discord.com/oauth2/authorize?client_id=1297367645548445747', '_blank') }}>
          <p className="text-[#ddd6f7] text-sm text-center font-semibold title">Add app</p>
        </div>
      </div>
    </div>    
  )
}

function App() {
  return (
    <div className="min-h-screen bg-[#261e26] z-10 h-screen w-full flex">
      <div className=" w-full md:w-3/6 max-w-full px-4 pt-0 bg-[#261e26] flex flex-col justify-start items-stretch mx-auto z-10 select-none overflow-x-hidden z-10">
        <Header />
        <div className="w-fit flex flex-col justify-start items-center relative z-50 h-full">
          <Banner />
          <Middle />
          <Bottom />
        </div>
      </div>
      <div className="absolute pointer-events-none select-none h-screen w-full bg-gradient-to-t from-[#4d5feb]/30 via-transparent via-30% to-transparent z-20 opacity-20"></div>
    </div>
  );
}

export default App;
